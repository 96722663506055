import { lazyLoadImages } from 'src/performant/lazyloadImages'
import { loadGoogleFonts } from 'src/performant/webfontGoogle'
import  klickartPerformantPhoneNumber  from 'src/performant/phoneNumber'
import  KlickartPublicWhatsapp  from 'src/performant/whatsapp'
import delay from 'src/performant/delay'
import smoothScroll from 'src/performant/smoothScroll'
import klickartIframePopup from 'src/performant/iframePopup';

window.addEventListener('load', loadGoogleFonts)
window.addEventListener('load', lazyLoadImages)
window.addEventListener('load', klickartPerformantPhoneNumber)
window.addEventListener('load', KlickartPublicWhatsapp)
window.addEventListener('load', delay)
window.addEventListener('load', smoothScroll)
window.addEventListener('load', klickartIframePopup);

