
let smoothScroll = () => {

  let calculateLength = (section) => {
    if (section === null)return
    return section.getBoundingClientRect().top
  }

  let elements = document.body.querySelectorAll('a[href*="#section"]:not([href="#"]):not([klickart-edit-link-type="popup"])')
  elements.forEach(element => {
    let sectionId = element.href.split('#')[1]
    let section = document.body.querySelector(`#${sectionId}`)
    let length = calculateLength(section)

    element.addEventListener('click', function(event){
      event.preventDefault()
      window.scrollTo({
        top: length,
        behavior: 'smooth'
      })
    })
  })

}

export default smoothScroll

